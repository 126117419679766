import moment from "moment";
import store from '@/shared/store'

const differenceDates = (dateString, currentDateString) => {
  var currentDate = moment(currentDateString)
  var date = moment(dateString);

  let hours = 0;
  let minutes = 0;
  var differenceMinutes = date.diff(currentDate, 'minutes');
  
  do{
    minutes+=60
    if(minutes>differenceMinutes){
      minutes = differenceMinutes-(minutes - 60)
      break
    }else
      hours+=1
  }while(minutes <= differenceMinutes )
  
  return { hours, minutes };
};

export default differenceDates;
