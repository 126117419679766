<template>
  <div class="scheduled-class bg-white py-3 px-5 px-md-3 border-r-10 bl-shadow mb-2" :style="total === 0 ? 'max-width:100%' : 'max-width:245px'">
    <header class="d-flex gap-3 align-items-center">
      <div class="header-user- header-user-small w-100 text-center">
        <div class="position-relative text-right user-picture mb-2">
          <avatar
            :image="info.photo ?? info.student_photo ?? '/assets/images/avatar.png'"
            shape="circle"
          />
        </div>
      </div>
      <h1 class="fs-5 text-primary-1 w-100">
        {{ typePerson !== "student" ? info.student_name : info.teacher_name }}
        {{
          typePerson !== "student"
            ? info.student_last_name
            : info.teacher_last_name
        }}
      </h1>
    </header>
    <div class="mb-3 mt-1">
      <div class="d-flex gap-1 justify-content-between mb-1">
        <p>Time: {{ info.lesson_start.substr(11, 5) }}</p>
      </div>
      <div class="d-flex gap-1 mb-1">
        Time zone:
        <p class="word-break-all">{{studentInformation.timezone_change?.split('/')[studentInformation.timezone_change?.split('/').length - 1]}}</p>
      </div>
      <div class="d-flex gap-1 justify-content-between mb-1">
        Date:
        {{
          formatDate(info.lesson_start, "american")
        }}
      </div>
    </div>
    <footer class="mt-2">
      <a
        :href="info?.link_zoom"
        target="_blank"
        :disabled="loading"
        class="
          btn
          text-white
          bg-secondary-1 bg-hover-secondary-1
          p-2
          px-3
          mb-2
          w-100
          bl-shadow
        "
      >
        Go to my class
      </a>

      <!-- <button
        v-if="type === 'normal'"
        :disabled="loading"
        class="btn text-secondary-1 text-hover-secondary-1 p-2 px-5 mb-2 w-100"
        @click="goViewMore"
      >
        View more
      </button> -->
      <button
        v-if="type === 'cancel'"
        :disabled="loading"
        class="btn text-secondary-1 text-hover-secondary-1 p-2 px-2 px-sx-5 mb-2 w-100"
        @click="cancelClass"
      >
        Cancel class
      </button>
    </footer>
  </div>
</template>

<script>
import formatDate from "@/shared/utils/changeTexts/formatDate";
import useClasses from "@/modules/students/composables/MyClasses/useClasses";
export default {
  name: "CardscheduledClassComponent",
  props: {
    info: {
      type: Object,
      default: {},
    },
    typePerson: {
      type: String,
      default: "teacher",
    },
    total: {
      type: Number,
      default: 1,
    },
    // name: {
    //   type: String,
    //   default: "Camila Cabello",
    // },
    // hour: {
    //   type: String,
    //   default: "00:00",
    // },
    // timeZone: {
    //   type: String,
    //   default: "(Time zone)",
    // },
    // date: {
    //   type: String,
    //   default: "MM/DD/YYYY",
    // },
    goMyClass: {
      type: Function,
    },
    goViewMore: {
      type: Function,
    },
    cancelClass: {
      type: Function,
    },
    type: {
      type: String,
      default: "normal",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      studentInformation,
    } = useClasses();
    return {
      formatDate,
      studentInformation
    };
  },
};
</script>

<style lang="scss" scoped>
.scheduled-class {
  max-width: 245px;
  width: 100%;
}
.text-right{
  text-align: right;
}
.picture {
  width: 4rem;
  height: 4rem;
}
</style>
