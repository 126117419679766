<template>
  <skeleton v-if="classesThisWeekPendingStudent.isLoading" borderRadius="10px" height="20rem" width="100%"
    class="mb-3 skeleton-dark" />

  <template v-else>
    <!-- Desktop -->
    <div class="d-none d-md-block">
      <Carousel :value="[...classesThisWeekPendingStudent.data, { card: true },
      { card: true },
      { card: true },
      { card: true },]" :numVisible="4" :numScroll="1" :circular="false" contentClass="carousel-custom-2 mt-4"
        indicatorsContentClass="d-none">
        <template #header>
          <h1 class="text-primary-1 fw-bold mb-0">Your class schedule</h1>
        </template>
        <template #item="slotProps">
          <div v-if="slotProps.data.card" class="
          bg-primary-5
          border-r-10
          text-center
          mb-2
          p-4
          d-flex
          flex-column
          gap-3
          justify-content-center
          align-items-center
          bl-shadow
          pointer
        " style="height: calc(100% - 0.5rem);  width: 245px;" @click="
          () =>
            router.push({
              name: 'student-my-classes-scheduled-class',
            })
        ">
            <p>
              <i class="pi pi-plus fs-1 fw-bold text-primary-1"></i>
            </p>
            <h1 class="text-primary-1 fw-bold fs-3 px-4 lh-sm">Schedule a class</h1>
          </div>
          <card-scheduled-class-component v-else :info="slotProps.data" type="cancel"
          :typePerson="userAuthenticated.role" :loading="isLoadingDelete"
          :cancelClass="() => toggleCancelClass(true, slotProps.data.id, slotProps.data)" />
        </template>
      </Carousel>
    </div>
    <!-- //Mobile -->
    <div class="d-block d-md-none px-3">
      <Carousel
        :value="classesThisWeekPendingStudent.data" :numVisible="1" :numScroll="1"
        contentClass="carousel-custom-2 px-2 mt-2 pb-3" indicatorsContentClass="d-none">
         <template #header>
          <h1 class="text-primary-1 fw-bold mb-0">Your class schedule</h1>
        </template>
        <template #item="slotProps">
          <card-scheduled-class-component :total="0" :info="slotProps.data" type="cancel"
            :typePerson="userAuthenticated.role" :loading="isLoadingDelete"
            :cancelClass="() => toggleCancelClass(true, slotProps.data.id, slotProps.data)" />
        </template>
      </Carousel>
      <div class="
          bg-primary-5
          border-r-10
          d-flex
          flex-column
          text-center
          justify-content-center
          p-5
        " @click="
          () =>
            router.push({
              name: 'student-my-classes-scheduled-class',
            })
        " style="height: 10rem;">
        <i class="pi pi-plus fs-1 fw-bold text-primary-1"></i>
        <h1 class="text-primary-0 fw-bold fs-5"> Schedule a class </h1>
      </div>

    </div>
  </template>


  <div class="mt-4">
    <skeleton v-if="classesWeekStudent.isLoading" borderRadius="10px" height="100vh" width="100%"
      class="mb-3 skeleton-dark" />
    <calendar-component v-else :events="events" :admin="false" title="Schedule for the week" titleMobile="Scheduled for the day" />
  </div>
  <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }"></ConfirmDialog>
</template>

<script>
import CalendarComponent from "@/shared/components/Calendar/CalendarComponent";
import CardScheduledClassComponent from "@/shared/components/Cards/CardScheduledClassComponent";
import useCalendar from "@/shared/composables/Calendar/useCalendar";
import { onBeforeMount, watchEffect, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import useClasses from "@/modules/students/composables/MyClasses/useClasses";
import usePrivateLayout from "@/shared/composables/Layouts/usePrivateLayout";
import "./styles.scss"
import moment from "moment";

export default {
  name: "MyClassesView",
  components: {
    CardScheduledClassComponent,
    CalendarComponent,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const events = ref([]);
    const { isLoadingDelete, cancelClass, toggleCancelClass } = useClasses();
    const { userAuthenticated } = usePrivateLayout();
    const {
      handleClientLoad,
      isLoadingCalendar,
      onEventClick,
      loadServices,
      classesThisWeekPendingStudent,
      classesWeekStudent,
    } = useCalendar();

    const setEvents = (classes, eventsCalendar) => {
      events.value = [];
      classes.forEach((classe) => {
        const minBetween = moment(store.getters['shared/currentTimeTimezone']).diff(classe.lesson_start, 'minutes')

        const btnValidate = minBetween < 30
        events.value.push({
          start: classe.lesson_start.replace(/-/g, "/"),
          end: classe.lesson_end.replace(/-/g, "/"),
          title: "My class",
          content: `<p>Class with ${classe.teacher_name} ${classe.teacher_last_name} ${btnValidate ? '</p> <a href="' + classe.link_zoom + '" target="_blank" class="w-100 mt-1 btn text-white bg-primary-1 btn-sm font-bold">Go to class</a><button id="' + classe.id + '" class="btn-cancel-calendar mt-1 w-100 btn text-white bg-primary-1 btn-sm">Cancel class</button>':''}`,
          class: "leisure",
          type: "class",
        });
      });
      let hash = {};
      events.value = events.value.filter(event => hash[event.start] ? false : hash[event.start] = true)
    };

    watchEffect(() => {
      let classesWeek = classesWeekStudent.value.data;
      let eventsCalendar = store.getters["shared/getCalendarEvents"];

      setEvents(classesWeek, eventsCalendar);
    });

    onBeforeMount(() => {
      handleClientLoad();
    });

    loadServices();

    return {
      router,
      toggleCancelClass,
      isLoadingCalendar,
      onEventClick,
      classesThisWeekPendingStudent,
      classesWeekStudent,
      events,
      userAuthenticated,
      isLoadingDelete,
      cancelClass,
    };
  },
};
</script>


