import { ref, computed, watchEffect, onBeforeMount } from "vue";
import differenceDates from "@/shared/utils/dates/differenceDates";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useConfirm } from "primevue/useconfirm";

import useSharedUI from "../../../../shared/composables/useSharedUI";
const useClasses = () => {
  const store = useStore();
  const times = ref({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const confirm = useConfirm();

  const router = useRouter();
  const isLoadingDelete = ref(false);
  const isLoadingTimes = ref(true)
  const scheduleds = ref([]);
  const teachers = ref({});
  const number_teachers = ref(0);
  const teachersSelect = ref([]);
  const scheduledsSelect = ref([]);
  const number_teachers_select = ref(0);
  const change = ref(false);
  const { sendNotificationCancelClass } = useSharedUI();


  onBeforeMount( async () => {
    const userAuthenticated = store.getters["shared/getUserAuthentication"]
    store.commit("students/scheduledClass", { data: [] });
    if(userAuthenticated.completed_info){
      const  { success , json  } =  await store.dispatch("students/timesAvailables");
      if(success){
        times.value = json
        isLoadingTimes.value = false
      }
    }
  });

 

  const selectTime = (time, date, dateNext, type, idFound) => {
    const newId = scheduleds.value[scheduleds.value.length-1]
    if (type === "add") {
      let info = {
        id: newId ? newId.id+1 : 0,
        date: date.substr(0, 10),
        time_start: `${time}:00`,
        time_end: `${dateNext}:00`,
      };
      scheduleds.value.push(info);
    } else {
      scheduleds.value = scheduleds.value.filter((item) => item.date+" "+item.time_start !== date.substr(0,10) + " " + (time.length <= 5 ? time + ":00" : time));
    }
    store.commit("students/scheduledClass", { data: scheduleds.value });
  };

  const selectTeacherTime = (time, date, dateNext, type, idFound) => {
    const newId = scheduledsSelect.value[scheduledsSelect.value.length-1]
    if (type === "add") {
      let info = {
        id: newId,
        date: date.substr(0, 10),
        time_start: `${time}:00`,
        time_end: `${dateNext}:00`,
      };
      scheduledsSelect.value.push(info);
    } else {
      scheduledsSelect.value = scheduledsSelect.value.filter((item) => item.date+" "+item.time_start !== date.substr(0,10) + " " + (time.length <= 5 ? time + ":00" : time));
    }
    store.commit("students/scheduledClass", { data: scheduledsSelect.value });
  };

  const selectByTeacher = (data) => {
    let teachersData = teachersSelect.value;
    const notification = { isOpen: true };
    notification.status = "success";
    let found = teachersData.find((p) => p.teacher_id === data.teacher_id);
    const name = data.name[0].toUpperCase() + data.name.substring(1);
    if (found === undefined) {
      if (teachersData.length === 0) {
        teachersData.push(data);
        notification.message = `The teacher <span class="text-capitalize">${name}</span> was added.`;
        number_teachers_select.value++;
        store.commit("students/setTeachersAll", { search: data.teacher_id });
        store.commit("shared/uiNotificationStatus", notification);
        store.dispatch("shared/resetNotificationStatus");
      } else {
        teachersData = teachersData.filter((p) => p.id !== data.id);
        teachersSelect.value = teachersData;
        store.commit("students/teachersClass", { data: teachersSelect.value });
        teachersData.push(data);
        notification.message = `The teacher <span class="text-capitalize">${name}</span> was added.`;
        store.commit("students/setTeachersAll", { search: data.teacher_id });
        store.commit("shared/uiNotificationStatus", notification);
        store.dispatch("shared/resetNotificationStatus");
      }
      teachersSelect.value = teachersData;
      store.commit("students/teachersClass", { data: teachersSelect.value });
    } else {
      teachersData = teachersData.filter((p) => p.id !== data.id);
      notification.message = `The teacher <span class="text-capitalize">${name}</span> was removed.`;
      number_teachers_select.value--;
      store.commit("shared/uiNotificationStatus", notification);
      store.dispatch("shared/resetNotificationStatus");
      teachersSelect.value = teachersData;
      store.commit("students/teachersClass", { data: teachersSelect.value });
    }
  };

  const deleteClass = (data, type = "time") => {
    let teachersdata = store.getters["students/teachersClass"];
    let scheduledsdata = store.getters["students/scheduledClass"];

    if (type === "time") {
      scheduledsdata.data = scheduledsdata.data.filter(
        (item) => item.id !== data.scheduled_id
      );
      teachersdata.data[data.scheduled_id] = [];

      store.commit("students/scheduledClass", { data: scheduledsdata.data });
      scheduleds.value = scheduledsdata.data;
      store.commit("students/teachersClass", { data: teachersdata.data });
      teachers.value = teachersdata.data;
      number_teachers.value--;
    } else {
      scheduledsSelect.value = scheduledsSelect.value.filter(
        (item) => item.id !== data.id
      );
      store.commit("students/scheduledClass", { data: scheduledsSelect.value });
      scheduleds.value = scheduledsSelect.value;
    }
  };

  const parseDataUser = (type = "time") => {
    let info = [];
    if (type === "time") {
      scheduleds.value.forEach((scheduled) => {
          if(teachers.value[scheduled.id])
              info.push({ ...scheduled, ...teachers.value[scheduled.id], teacher_id: teachers.value[scheduled.id].id });
      });
    } else {
      scheduledsSelect.value.forEach((scheduled) => {
        teachersSelect.value.forEach((teacher) => {
          info.push({ ...scheduled, ...teacher });
        });
      });
    }
    store.commit("students/infoClassModal", { data: info });
  };

  const selectTeacher = (data, scheduledId) => {
    let teachersData = teachers.value;
    const notification = { isOpen: true };
    notification.status = "success";
    let found = Object.entries(teachersData).length > 0 ? teachersData[scheduledId] : undefined;
    const name = data.name[0].toUpperCase() + data.name.substring(1);
    if (found === undefined) {
      if (teachersData[scheduledId] === undefined) {
        teachersData[scheduledId] = {...data,scheduled_id:scheduledId};
        notification.message = `The teacher <span class="text-capitalize">${name}</span> was added.`;
        number_teachers.value++;
        store.commit("shared/uiNotificationStatus", notification);
        store.dispatch("shared/resetNotificationStatus");
      } else {
        delete teachersData[scheduledId];
        notification.message = `The teacher <span class="text-capitalize">${name}</span> was removed.`;
        store.commit("students/teachersClass", { data: teachersData });
        teachersData[scheduledId] = {...data,scheduled_id:scheduledId};
        notification.message = `The teacher <span class="text-capitalize">${name}</span> was added`;
        store.commit("shared/uiNotificationStatus", notification);
        store.dispatch("shared/resetNotificationStatus");
        teachers.value = teachersData;
        store.commit("students/teachersClass", { data: teachersData });
      }
      teachers.value = teachersData;
      store.commit("students/teachersClass", { data: teachersData });
    } else {
      delete teachersData[scheduledId];
      notification.message = `The teacher <span class="text-capitalize">${name}</span> was removed.`;
      store.commit("students/teachersClass", { data: teachersData });
      teachersData[scheduledId] = {...data,scheduled_id:scheduledId};
      notification.message = `The teacher <span class="text-capitalize">${name}</span> was added.`;
      store.commit("shared/uiNotificationStatus", notification);
      store.dispatch("shared/resetNotificationStatus");
      teachers.value = teachersData;
      store.commit("students/teachersClass", { data: teachersData });
    }
  };

  const isValidCancel = (classe) => {
    let { minutes } = differenceDates(new Date(classe.date_start), store.getters['shared/currentTimeTimezone']);
    return minutes > 15;
  };

  const cancelClass = async (id, classe) => {
    isLoadingDelete.value = true;
    store.commit("students/cancelClass", { isLoading : true });
    if (isValidCancel(classe)) {
      await store.dispatch("students/cancelClass", { id });
      await store.dispatch("shared/resetNotificationStatus");
      await store.dispatch("students/classesTakenThisWeek");
      await store.dispatch("students/classesThisWeekPending");
      await store.dispatch("students/dashboardStart");
      const student = store.getters["students/studentInformation"];
      sendNotificationCancelClass(
        classe,
        student.name + " " + student.last_name
      );
    }
    toggleCancelClass(false)
    store.commit("students/cancelClass", { isLoading : false });
    isLoadingDelete.value = false;
  };

  const teachersClass = computed(() => {
    return store.getters["students/teachersClass"];
  });

  const availableTeachers = computed(() => {
    return store.getters["students/availableTeachers"];
  });

  const getAvailableTeachers = computed(() => {
    const availableTeachers = store.getters["students/availableTeachers"];
    const newAvailableTeachers = [];

    if (Object.entries(availableTeachers.data).length > 0) {
      Object.entries(availableTeachers.data).forEach(([key, value]) => {
        const item = scheduleds.value.find(date => date.date+' '+date.time_start ==key)
        if(!!item){
          newAvailableTeachers[item.id] = key;
        }
      });
      return newAvailableTeachers;
    }
    return [];
  });

  const infoClassModal = computed(() => {
    return store.getters["students/infoClassModal"];
  });

  const getAvailablescheduleds = computed(() => {
    const availablescheduleds = store.getters["students/availablescheduleds"];
    const newAvailablescheduleds = [];

    if (Object.entries(availablescheduleds.data).length > 0) {
      Object.entries(availablescheduleds.data).forEach(([key, value]) => {
        newAvailablescheduleds.push(key);
      });
      return newAvailablescheduleds;
    }
    return [];
  });

  const availablescheduleds = computed(() => {
    return store.getters["students/availablescheduleds"];
  });

  const studentInformation = computed(() => {
    return store.getters["students/studentInformation"];
  });

  const goToProfile = () => {
    router.push({ name: "student-profile" });
  };

  const confirmCancelClass = computed(() => {
    return store.getters["students/cancelClass"];
  });

  const toggleCancelClass = (isOpen,id=null,classe=null) => {
    if(isOpen){
      store.commit("students/cancelClass", { isOpen , id , classe });
    }else{
      store.commit("students/cancelClass", { isOpen });
    }
  }

  watchEffect(() => {
    const { data } = store.getters["students/scheduledClass"];
    if (data.length > 0 || Object.entries(data).length > 0) {
      change.value = true;
    } else {
      teachers.value = {};
      number_teachers.value = 0;
      scheduledsSelect.value = []
      scheduleds.value = [];
      !change.value
        ? store.commit("students/teachersClass", { data: [] })
        : null;
      change.value = true;
    }
  });

  watchEffect(() => {
    const { data } = store.getters["students/teachersClass"];
    teachersSelect.value = data;
    number_teachers_select.value = data.length;
  
  })
  

  return {
    goToProfile,
    confirmCancelClass,
    studentInformation,
    availableTeachers,
    availablescheduleds,
    cancelClass,
    deleteClass,
    toggleCancelClass,
    teachers,
    teachersClass,
    selectTime,
    parseDataUser,
    scheduleds,
    isLoadingTimes,
    selectTeacher,
    selectTeacherTime,
    scheduledsSelect,
    isLoadingDelete,
    getAvailableTeachers,
    number_teachers,
    infoClassModal,
    getAvailablescheduleds,
    selectByTeacher,
    number_teachers_select,
    times,
    teachersSelect
  };
};

export default useClasses;
